import React, {useState, useEffect} from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import {formoptions} from "./ContextFormOptions";

const ProviderFormOptions = ({children}) =>{

  const [isLoaded, setIsLoaded] = useState(false);
  const [formOptions, setFormOptions] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    (async () => {

      const token = await getAccessTokenSilently();

      try {

        const grabFormOptions = await fetch(`${process.env.REACT_APP_MID_API_URL}/api/FormOptions`, {
          method: "GET",
          headers: {
            'content-type': 'text/plain',
            Authorization: `Bearer ${token}`,
          },
        });

        const optionData = await grabFormOptions.json();
        setFormOptions(optionData);
        setIsLoaded(true);
      } catch(e) {
        console.log(e);
      }
    })();
  }, [getAccessTokenSilently]);

     
   const { Provider } = formoptions
   return(
      <Provider value={{options: formOptions, isFormOptionsLoaded: isLoaded}}>
        {children}
      </Provider>
   )
}

export default ProviderFormOptions