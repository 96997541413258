import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { Auth0Provider } from "@auth0/auth0-react"
import ProviderRole from "./components/provider-role/ProviderRole";
import ProviderFormOptions from './components/provider-form-options/ProviderFormOptions'
import ProviderCustomerID from './components/provider-customer-id/ProviderCustomerID'

const container = document.getElementById('root')
const root = createRoot(container)


root.render(
  <Auth0Provider
  domain={process.env.REACT_APP_AUTH0_DOMAIN}
  clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
  authorizationParams={{
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: "openid read:current_user update:current_user_metadata orderid create:order update:order offline_access"
  }}
  useRefreshTokens={true}
  useRefreshTokensFallback={true}
  cacheLocation='localstorage'
  >
  <ProviderRole>
    <ProviderCustomerID>
    <ProviderFormOptions>
      <Provider store={store}>
        <App />
      </Provider>
    </ProviderFormOptions>
    </ProviderCustomerID>
  </ProviderRole>
  </Auth0Provider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
