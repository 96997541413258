import React, {useState, useEffect} from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import {customerid} from "./ContextCustomerID";

const ProviderCustomerID = ({children}) =>{

  const [isLoaded, setIsLoaded] = useState(false);
  const [customerID, setCustomerID] = useState();
  const [isFullCustomer, setIsFullCustomer] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    (async () => {

      const token = await getAccessTokenSilently();

      try {

        const grabCustomer = await fetch(`${process.env.REACT_APP_MID_API_URL}/api/Customers/Current`, {
          method: "GET",
          headers: {
            'content-type': 'text/plain',
            Authorization: `Bearer ${token}`,
          },
        });

        const optionData = await grabCustomer.json();
        setCustomerID(optionData);
        setIsLoaded(true);
        setIsFullCustomer(true);
      } catch(e) {
        setIsFullCustomer(false);
      }
    })();
  }, [getAccessTokenSilently]);

     
   const { Provider } = customerid
   
   return(
      <Provider value={{customer: customerID, isCustomerIDLoaded: isLoaded, isFullCustomer: isFullCustomer}}>
        {children}
      </Provider>
   )
}

export default ProviderCustomerID