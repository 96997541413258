import React, {useState, useEffect} from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import {context} from "./ContextRole";

const ProviderRole = ({children}) =>{

  const { user } = useAuth0();
     
   const { Provider } = context
   return(
      <Provider value={user}>
        {children}
      </Provider>
   )
}

export default ProviderRole